import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DataService } from 'src/app/services/data/data.service';


@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit{
  selectedButton: string = 'basic_monthly'; // Initialize with an empty string
  selectedButton_desktop: string = 'basic_monthly'; // Initialize with an empty string
  showContact:boolean = false
  isItMobile: boolean = false;

  constructor(private router: Router,private location: Location,private dataService: DataService,) { }
  selectButton(buttonType: string) {
    this.selectedButton = buttonType; // Set the selected button type
  }


  ngOnInit(): void {
    this.dataService.data$.subscribe((data: any) => {
      if (data.message == 'dismiss') {
        this.showContact = false
      } else {
      if (this.isItMobile) {
      this.showContact = false
      this.navigateTo('contact')
      } else {
        this.showContact = true
      }
    }
    });
}
dismissBlurryComponent() {
  this.showContact = false;
}

 navigateTo(route: any) {
   this.router.navigate([route]);

 }
  selectButton_desktop(buttonType: string) {
    this.selectedButton_desktop = buttonType; // Set the selected button type
  }
  goToPayment() {
    const dataToSend = { type:this.selectedButton}; // Replace with your actual data
    this.router.navigate(['payment-stripe'], { state: { data: dataToSend } });
  }
  goToPaymen_desktop() {
    const dataToSend = { type:this.selectedButton_desktop}; // Replace with your actual data
    this.router.navigate(['payment-stripe'], { state: { data: dataToSend } });
  }
  goBack() {
    this.location.back();
  }

 goToWhatsAppForm(){
  window.location.href = 'https://whatsform.com/ukjbo6';
 }
}

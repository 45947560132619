import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpResponse } from '@angular/common/http';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/admin/AuthService.service';
// import { SessionService } from 'src/app/services/admin/SessionService.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css'],
  animations: [

    trigger('usernameLabelAnimation', [state('up', style({
      transform: 'translateY(-100%) ',
      fontSize: '12px',
      color: '#757575',
      margintop: '7px'
    })),
    state('down', style({
      transform: 'translateY(0) scale(1)',
      fontSize: '16px',
      color: '#000'
    })),
    transition('up => down', animate('200ms ease-out')),
    transition('down => up', animate('200ms ease-in')),
    ]),
    trigger('passwordLabelAnimation', [state('up', style({
      transform: 'translateY(-100%) ',
      fontSize: '12px',
      color: '#757575',
      margintop: '7px'
    })),
    state('down', style({
      transform: 'translateY(0) scale(1)',
      fontSize: '16px',
      color: '#000'
    })),
    transition('up => down', animate('200ms ease-out')),
    transition('down => up', animate('200ms ease-in')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None
})
export class AdminLoginComponent {
  isUsernameRequiered: any;
  username: any;
  usernameIsInputFocused: any;
  isPasswordRequiered: any;
  password: any;
  passwordIsInputFocused: any;
  constructor(private authService: AuthService, private router: Router) {
    // excelService.readExcel();
  }

  onInputFocus(inputType: string): void {

    this.resetAllInputFocusFlags();
    if (inputType === 'username') {
      this.usernameIsInputFocused = true;
    }
    if (inputType === 'password') {
      this.passwordIsInputFocused = true;
    }
  }
  onInputClick(inputType: string): void {
    // Additional logic for input click if needed
  }
  onInputBlur(inputType: string): void {

    if (inputType === 'username' && !this.username) {
      this.usernameIsInputFocused = false;

    }
    if (inputType === 'password' && !this.password) {
      this.passwordIsInputFocused = false;

    }
  }
  private resetAllInputFocusFlags(): void {


    if (this.username == '') {
      this.usernameIsInputFocused = false;
    }
    if (this.password == '') {
      this.passwordIsInputFocused = false;
    }
  }

  onNextClick() {

    // mobile
    this.isUsernameRequiered = true;
    this.isPasswordRequiered = true;


    if ((this.username == '' || this.username === null) || (this.password == '' || this.password === null)) {
      // All values are either empty strings or null
    } else {
      this.login(this.username, this.password);
    }
  }


  login(username:string,password:string) : void {

    this.authService.login(username, password).subscribe(
      (response: HttpResponse<any>) => {
        const sessionId = response.headers.get('your-session-id-header');
        const csrfToken = response.headers.get('your-csrf-token-header');
        const firstName = response.body?.first_name; 
        const lastName = response.body?.last_name; 
        const privilege = response.body?.privilege; 
        const date_joined = response.body?.date_joined;
        const id = response.body?.id; 
        const username = response.body?.username; 
        const email = response.body?.email; 

       this.authService.setSession(sessionId || '',csrfToken || '',firstName,lastName,privilege,date_joined,id,username,email)
      //  if (response.body.get('privilege') == 'master') {
        this.router.navigate(['registration']);
      //  }
      },
      (error: any) => {
        // Handle error
        console.error('Login error:', error);
      }
    );
  }
}


<div class="login-container_lol">
    <div class="card_lol">
        <img src="../../../assets/admin/admin-login-logo.png" alt="Logo" class="logo_lol">
        <div class="formi_lol">
            <div class="input-group_lol">
                <div class="custom-input-container-group_lol top_lol"
                    [ngClass]="{'required-field_lol': isUsernameRequiered && !username}" (click)="onInputClick('username')"
                    (focusout)="onInputBlur('username')"> <input [(ngModel)]="username"
                        (focus)="onInputFocus('username')" class="custom-input-group_lol" /> <label
                        [@usernameLabelAnimation]="usernameIsInputFocused ? 'up' : 'down_lol'">
                        User ID
                    </label>
                    <div class="warning-logo_lol" *ngIf="isUsernameRequiered && !username">
                        <img alt="showing required username" src="../../../../../../assets/requiered.svg">
                    </div>
                </div>
            </div>
            <div class="input-group_lol">
                <div class="custom-input-container-group_lol bottom_lol"
                    [ngClass]="{'required-field_lol': isPasswordRequiered && !password}" (click)="onInputClick('password')"
                    (focusout)="onInputBlur('password')">
                    <input type="password" [(ngModel)]="password" (focus)="onInputFocus('password')"
                        class="custom-input-group_lol" /> <label
                        [@passwordLabelAnimation]="passwordIsInputFocused ? 'up' : 'down_lol'">
                        Password
                    </label>
                    <div class="warning-logo_lol" *ngIf="isPasswordRequiered && !password"> <img
                            src="../../../../../../assets/requiered.svg"> </div>
                </div>
            </div>
            <div class="separator_lol"></div>
            <button (click)="onNextClick()" type="submit">Continue</button>
        </div>
    </div>
</div>

<div class="mobile_payment">
    <!-- <app-header [showBackButton]="true" [activeButton]="'contact'" style="position: fixed; z-index: 2;"></app-header> -->

    <div class="section_mobile_payment" >

        <div class="bottom-box_mobile_payment" >

                <div class="box-content_mobile_payment">
                    <div class="container_confirm">
                        <div class="left-column_confirm">
                            <img src="../../../assets/checkGreen50.png" alt="Image">
                        </div>
                        <div class="right-column_confirm">
                            <div class="confirm-title">Client ID 6604</div>
                            <div class="confirm-subtitle">Thank You Joe!</div>
                        </div>
                    </div>
                </div>
                <div class="box-title_mobile_payment">
                    You’re ready to start your journey with Elevate, building long lasting relationships with active
                    customers in your community.
                    <button (click)="goToWhatsAppForm()" class="box-patment-button_mobile_payment">Complete Setup 
                        <!-- <img width="17" height="17" src="../../../../assets/arrowCompleteSetup.png"> -->
                    </button>
                </div>
            </div>
        </div>
</div>
<div class="desktop_payment">
    <!-- <app-header ></app-header> -->
    <!-- <app-header [activeButton]="'contact'" style="position: fixed; z-index: 2;"></app-header> -->

    <div class="section_payment">
        <div class="container-how_up_payment">
            <div id="container_how-top_payment">
                <div class="header-top_payment"> <button class="back-button_payment" (click)="goBack()"> <img
                            class="back-icon_payment" src="../../../../../assets/leftArrow.svg" alt="Back"> </button>
                    <div class="title-top_payment">Order Confirmation</div>

                    <button class="icon-button_payment" (click)="goToWhatsAppForm()">
                        <span> Complete Setup</span> <img class="icon_payment"
                            [src]="'../../../../../assets/blueArrow.png'" alt="Icon"> </button>
                </div>


                <div class="seperation-top_payment"></div>
                <div class="section_mobile_payment">


                    <div class="bottom-box_mobile_payment">
                        <div class="box-content_mobile_payment">
                            <div class="container_confirm">
                                <div class="left-column_confirm">
                                    <img src="../../../assets/checkGreen50.png" alt="Image">
                                </div>
                                <div class="right-column_confirm">
                                    <div class="confirm-title">Client ID 6604</div>
                                    <div class="confirm-subtitle">Thank You Joe!</div>
                                </div>
                            </div>
                        </div>
                        <div class="confirm-description">
                            You’re ready to start your journey with Elevate, building long lasting relationships with
                            active customers in your community.
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- <app-blurry-contact-container (dismiss)="dismissBlurryComponent()"
        *ngIf="showContact"></app-blurry-contact-container> -->
</div>
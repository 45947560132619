import { Component } from '@angular/core';

@Component({
  selector: 'app-book-calender',
  templateUrl: './book-calender.component.html',
  styleUrls: ['./book-calender.component.css']
})
export class BookCalenderComponent {

}


import { ViewportScroller } from '@angular/common';
import { Component, OnInit, AfterViewInit, ElementRef, Renderer2, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data/data.service';
import { ScreenSizeService } from 'src/app/services/screen-size/screen-size.service';
import { WaitingListService } from 'src/app/services/wishlist/waitlist.service';
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css'],
  encapsulation: ViewEncapsulation.Emulated
  
})
export class ResourcesComponent implements OnInit, AfterViewInit, OnDestroy {
  isItMobile: boolean = false;
  private screenWidthSubscription!: Subscription;
  screenWidth: number = 0;
  showContact: boolean = false
  showBook: boolean = false
   cards = [
    { 
      label: 'SEO', 
      title: 'Simple & flexible pricing', 
      description: 'Showcasing the finest in culinary photography visual.' ,
      bg:'#C5C0F6',
      image:'../../../assets/resources/food.svg',
      buttonbg:'#2C3B62'
    },
    { 
      label: 'Marketing', 
      title: 'Simple & flexible pricing', 
      description: 'Showcasing the finest in culinary photography visual.' ,
      bg:'#C0DBF6',
      image:'../../../assets/resources/food.svg',
      buttonbg:'#312C62'
      
    },
    { 
      label: 'Photography', 
      title: 'The Art of Food Photography: How...', 
      description: 'One of the benefits of learning food photography is the ability to capture the beauty of your meals.' ,
      bg:'#C0F6EC',
      image:'../../../assets/resources/food.svg',
      buttonbg:'#2C6247'
    },
    { 
      label: 'SEO', 
      title: 'Simple & flexible pricing', 
      description: 'Showcasing the finest in culinary photography visual.' ,
      bg:'#F6C0C0',
      image:'../../../assets/resources/food.svg',
      buttonbg:'#622C2C'
    },
    { 
      label: 'Marketing', 
      title: 'Simple & flexible pricing', 
      description: 'Showcasing the finest in culinary photography visual.' ,
      bg:'#F0C0F6',
      image:'../../../assets/resources/food.svg',
      buttonbg:'#622C56'
    },
    { 
      label: 'Photography', 
      title: 'The Art of Food Photography: How...', 
      description: 'One of the benefits of learning food photography is the ability to capture the beauty of your meals.' ,
      bg:'#F6F4C0',
      image:'../../../assets/resources/food.svg',
      buttonbg:'#62532C'
    },
    { 
      label: 'SEO', 
      title: 'Simple & flexible pricing', 
      description: 'Showcasing the finest in culinary photography visual.' ,
      bg:'#C5C0F6',
      image:'../../../assets/resources/food.svg',
      buttonbg:'#312C62'
    },
    { 
      label: 'Marketing', 
      title: 'Simple & flexible pricing', 
      description: 'Showcasing the finest in culinary photography visual.' ,
      bg:'#C0DBF6',
      image:'../../../assets/resources/food.svg',
      buttonbg:'#2C3B62'
    },
    { 
      label: 'Photography', 
      title: 'The Art of Food Photography: How...', 
      description: 'One of the benefits of learning food photography is the ability to capture the beauty of your meals.' ,
      bg:'#C0F6EC',
      image:'../../../assets/resources/food.svg',
      buttonbg:'#2C6247'
    },
   
  ];

  

  constructor(private dataService: DataService,private screenSizeService: ScreenSizeService,private renderer: Renderer2, private router: Router, private waitingListService: WaitingListService, private elementRef: ElementRef,private vps:ViewportScroller ) {
 
  }

  
  
  
  ngOnInit(): void {
    this.dataService.data$.subscribe((data: any) => {
      if (data.message == 'dismiss') {
        this.showContact = false
        this.showBook = false
      } else if (data.message == 'Consultations') {
        this.showBook = true
      } else {
        this.showContact = true
      }
    });
    this.screenWidthSubscription = this.screenSizeService
      .getScreenWidth()
      .subscribe((width) => {
        this.screenWidth = width;
        // this.adjustComponentBehavior();
        this.adjustContent();
      });

      
      }
  

  ngAfterViewInit() {

  
  }
  adjustContent() {
    if (this.screenWidth < 700) {
      this.setSmallScreenContent();
    } else if (this.screenWidth >= 800 && this.screenWidth <= 1200) {
      this.setIPadContent();
    } else {
      this.setLargeScreenContent();
    }
  }
  setSmallScreenContent() {
  
    this.isItMobile = true;
  }
  setIPadContent() {
    this.isItMobile = false;
  
  }
  setLargeScreenContent() {
  
    this.isItMobile = false;
  }
 



  navigateTo(route: string): void {
    this.router.navigate([route]);
  }
  ngOnDestroy(): void {
      this.screenWidthSubscription.unsubscribe();
      
  }
  dismissBlurryComponent() {
    this.showContact = false;
    this.showBook = false;
  }
}














// {


//   handleDataChange(buttonName: any) {
//     console.log('Button clicked:', buttonName);
//   }
//   cards = [
//     { 
//       label: 'SEO', 
//       title: 'Simple & flexible pricing', 
//       description: 'Showcasing the finest in culinary photography visual.' ,
//       bg:'#C5C0F6',
//       image:'../../../assets/resources/food.svg',
//       buttonbg:'#2C3B62'
//     },
//     { 
//       label: 'Marketing', 
//       title: 'Simple & flexible pricing', 
//       description: 'Showcasing the finest in culinary photography visual.' ,
//       bg:'#C0DBF6',
//       image:'../../../assets/resources/food.svg',
//       buttonbg:'#312C62'
      
//     },
//     { 
//       label: 'Photography', 
//       title: 'The Art of Food Photography: How...', 
//       description: 'One of the benefits of learning food photography is the ability to capture the beauty of your meals.' ,
//       bg:'#C0F6EC',
//       image:'../../../assets/resources/food.svg',
//       buttonbg:'#2C6247'
//     },
//     { 
//       label: 'SEO', 
//       title: 'Simple & flexible pricing', 
//       description: 'Showcasing the finest in culinary photography visual.' ,
//       bg:'#F6C0C0',
//       image:'../../../assets/resources/food.svg',
//       buttonbg:'#622C2C'
//     },
//     { 
//       label: 'Marketing', 
//       title: 'Simple & flexible pricing', 
//       description: 'Showcasing the finest in culinary photography visual.' ,
//       bg:'#F0C0F6',
//       image:'../../../assets/resources/food.svg',
//       buttonbg:'#622C56'
//     },
//     { 
//       label: 'Photography', 
//       title: 'The Art of Food Photography: How...', 
//       description: 'One of the benefits of learning food photography is the ability to capture the beauty of your meals.' ,
//       bg:'#F6F4C0',
//       image:'../../../assets/resources/food.svg',
//       buttonbg:'#62532C'
//     },
//     { 
//       label: 'SEO', 
//       title: 'Simple & flexible pricing', 
//       description: 'Showcasing the finest in culinary photography visual.' ,
//       bg:'#C5C0F6',
//       image:'../../../assets/resources/food.svg',
//       buttonbg:'#312C62'
//     },
//     { 
//       label: 'Marketing', 
//       title: 'Simple & flexible pricing', 
//       description: 'Showcasing the finest in culinary photography visual.' ,
//       bg:'#C0DBF6',
//       image:'../../../assets/resources/food.svg',
//       buttonbg:'#2C3B62'
//     },
//     { 
//       label: 'Photography', 
//       title: 'The Art of Food Photography: How...', 
//       description: 'One of the benefits of learning food photography is the ability to capture the beauty of your meals.' ,
//       bg:'#C0F6EC',
//       image:'../../../assets/resources/food.svg',
//       buttonbg:'#2C6247'
//     },
   
//   ];

// }

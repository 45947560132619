import { AfterViewInit, Component, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-calendly',
  templateUrl: './calendly.component.html',
  styleUrls: ['./calendly.component.css']
})
export class CalendlyComponent implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {23432
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
// Import necessary modules or use 'declare const Calendly: any;' if not already imported

const configurationOptions = {
  url: 'https://api.leadconnectorhq.com/widget/booking/4xkApKVlCFn8WNweQ1Qw',
  hideEventTypeDetails: true,
  hideGdprBanner: true,
};

script.text = `Calendly.initInlineWidget(${JSON.stringify(configurationOptions)});`;

    this.renderer.appendChild(this.el.nativeElement, script);
  }
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Add caching headers for image requests
    if (request.url.endsWith('.jpg') || request.url.endsWith('.png')) {
      request = request.clone({
        setHeaders: {
          'Cache-Control': 'max-age=31536000, public', // Cache for 1 year
        },
      });
    }

    return next.handle(request);
  }
}

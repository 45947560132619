// screen-size.service.ts
import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private screenWidth = new BehaviorSubject<number>(window.innerWidth);

  constructor() {
    this.checkWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkWidth();
  }

  private checkWidth(): void {
    this.screenWidth.next(window.innerWidth);
  }

  getScreenWidth(): BehaviorSubject<number> {
    return this.screenWidth;
  }
}

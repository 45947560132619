import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { SearchResult } from 'src/app/models/ search-result.model';
// import { HeaderComponent } from 'src/app/components/Reusable/header/header.component';
import { DataService } from 'src/app/services/data/data.service';
import { ScreenSizeService } from 'src/app/services/screen-size/screen-size.service';
import { Location } from '@angular/common';
import { CsvData } from 'src/app/models/CsvData.model';

@Component({
  selector: 'app-search-overlay',
  templateUrl: './search-overlay.component.html',
  styleUrls: ['./search-overlay.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,

})
export class SearchOverlayComponent implements OnInit , AfterViewInit {

  screenWidth: number = 0;

  private screenWidthSubscription!: Subscription;
  isItMobile: boolean = false;
  thingsVisible: boolean = true;
  isMobileKeyboardOpen: boolean = false;



  ngOnDestroy(): void {
    this.screenWidthSubscription.unsubscribe();
  }

  onInputFocus() {
    this.isMobileKeyboardOpen = true;
    this.updateDropdownMaxHeight();
  }

  onInputBlur() {
    this.isMobileKeyboardOpen = false;
    this.updateDropdownMaxHeight();
  }
  onInputChangeOver() {
    this.showDropdownOver = this.searchInputValueOver.length > 0;
    this.updateDropdownMaxHeight();
    // Additional logic if needed
  }
  updateDropdownMaxHeight() {
    const dropdown = document.querySelector('.search-dropdown-over') as HTMLElement;
    if (dropdown) {
      // Set max-height based on whether the keyboard is open or closed
      dropdown.style.maxHeight = this.isMobileKeyboardOpen ? '50vh' : 'none';
    }
  
  }
  private adjustComponentBehavior(): void {
    // Add logic here to adjust your component's behavior based on screen size
    if (this.screenWidth < 1000) {
      this.reloadThigs()
      this.isItMobile = true
    } else {
      this.reloadThigs()
      this.isItMobile = false

    }
  }




goToBook() {
  this.router.navigate(['/book-agency']);
}
goToHowWork() {
  this.router.navigate(['/how']);
}
goToAgency() {
  this.router.navigate(['/agencies']);
}
clearInputOver() {
this.searchInputValueOver = '';
this.showDropdownOver = false
}

searchControl = new FormControl();
searchControlOver = new FormControl();

searchInputValue: any;
searchInputValueOver: any;
showContact:boolean = false
  ngOnInit(): void {
    this.screenWidthSubscription = this.screenSizeService
      .getScreenWidth()
      .subscribe((width) => {
        this.screenWidth = width;
        // Adjust your component's behavior based on screen size
        this.adjustComponentBehavior();
      });

  }

  
  navigateToSection(section: number) {
    let targetElement: HTMLElement;

    switch (section) {
      case 1:
        this.navigateTo('')
        break;
      case 2:
       
      this.navigateTo('/book-agency')
        break;
      case 3:
        // targetElement = document.getElementById("section3")!;
        this.navigateTo('/how')
        break;
      case 4:
        this.navigateTo('/agencies')
        // targetElement = document.getElementById("section4")!;
        break;
      case 5:
        // targetElement = document.getElementById("section5")!;
        this.navigateTo('/contact')
        return;
      default:
        // Handle invalid section number
        return;
    }
  }
  constructor(private cdr: ChangeDetectorRef, private location:Location,private router: Router, private activatedRoute: ActivatedRoute,private dataService: DataService,private screenSizeService: ScreenSizeService) {
     // Subscribe to the Observable in the child component
     
  }
  navigateTo(route: any) {
    this.router.navigate([route]);

  }
  showDropdown = false;
  showDropdownOver = false;

  results:CsvData[] = [];



  shuffleArray(array: any[]): void {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  
  filterResults(value: string): CsvData[] {
    const filterValue = value.toLowerCase();
    return this.results.filter(result =>
      result.Name.toLowerCase().includes(filterValue)
    );
  }
  // filterResultsOver(value: string): SearchResult[] {
  //   const filterValueOver = value.toLowerCase();
  //   return this.resultsOver.filter(result =>
  //     result.name.toLowerCase().includes(filterValueOver)
  //   );
  // }
  onSearchFocus(): void {
    this.showDropdown = true;
  }

  onOptionSelected(result: SearchResult): void {
    this.showDropdown = false;
  }
  filteredResults: Observable<CsvData[]> = this.searchControl.valueChanges.pipe(
    startWith(''),
    map(value => this.filterResults(value)
    // .slice(0, 8)
    )

    );
  // filteredResultsOver: Observable<SearchResult[]> = this.searchControlOver.valueChanges.pipe(
  //   startWith(''),
  //   map(value => this.filterResultsOver(value))
  // );
  onInputChange() {
    this.showDropdown = this.searchInputValue.length > 0;
  }

  clickPlusButton() {
    let result =  
    { 
     instagram: '',
    name: this.searchInputValue,
    cuisine: '',
    country: '',
    city: ''
    }
    this.router.navigate(['/book-agency'], { state: { data: result } });

  }
  clickPlusButtonOver() {
    let result =  
    { 
     instagram: '',
    name: this.searchInputValueOver,
    cuisine: '',
    country: '',
    city: ''
    }
    this.router.navigate(['/book-agency'], { state: { data: result } });

  }
handleDropdownItemClick(result: any) {
  this.showDropdown = false;
  this.sendDataToReceiver(result)
}
handleDropdownItemClickOver(result: any) {
  this.showDropdownOver = false;
  this.sendDataToReceiver(result)
}
sendDataToReceiver(result: SearchResult) {
  // this.router.navigate(['/book-agency'], {  queryParams: { searchResult: result }  });
  this.router.navigate(['/book-form'], { state: { data: result } });

}

showOverlay: boolean = false;

  openOverlay(): void {
    this.disableScroll()
    this.showOverlay = true;
    this.sendDataToParent('close')
  }

  closeOverlay(): void {
    this.enableScroll()
    this.showOverlay = false;
    this.sendDataToParent('open')
    this.location.back();
  }

  @Output() dataEvent = new EventEmitter<string>();
  sendDataToParent(data:any) {
    const dataToSend = data;
    this.dataEvent.emit(dataToSend);
  }
  activeButton: string | null = null;

  handleDataChange(buttonName: any) {
    // Handle the navigation logic here
    // For example, you can navigate based on the buttonName
    // You can also set activeButton for highlighting the active button in the header
    console.log('Button clicked:', buttonName);
  }
  
  dismissBlurryComponent() {
    this.showContact = false;
  }
  reloadThigs() {
    // Toggle the visibility to recreate the dropdown
    this.thingsVisible = false;
    setTimeout(() => {
      this.thingsVisible = true;
    });
  }


  private scrollEnabled = true;

  enableScroll() {
    this.scrollEnabled = true;
    this.updateScroll();
  }

  disableScroll() {
    this.scrollEnabled = false;
    this.updateScroll();
  }

  private updateScroll() {
    document.body.style.overflow = this.scrollEnabled ? 'auto' : 'hidden';
  }


  // other component code
  ngAfterViewInit() {
   
  }
  getIconUrl(primaryCuisine: string,CuisineMap:string): string {
    // Check if primaryCuisine includes "Bar"
    if (primaryCuisine && primaryCuisine.toLowerCase().includes('bar') || CuisineMap && CuisineMap.toLowerCase().includes('others')) {
      return '../../../../assets/Home/bar.svg';
    } else {
      return '../../../../assets/Home/resturant.svg';
    }
  }
  
}




import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MyURLService {
  // mainApiUrl = 'https://elevate-hmg.com/service/api/';
  mainApiUrl = 'https://elevatesocialmarketing.com/back/api/';

  // https://elevatesocialmarketing.com
  // mainApiUrlImage = 'https://elevate-hmg.com/';
  mainApiUrlImage = 'https://elevatesocialmarketing.com/';

  // mainApiUrl = 'http://127.0.0.1:8000/api/';
  // mainApiUrlImage = 'http://127.0.0.1:8000/';
  // http://localhost:8000/media/points_images/@fafaspita-001.jpg
  constructor() {}

  getFullUrlForService(completion: string){
    return  this.mainApiUrl + completion
  }

  getFullUrlForImages(completion: string) {
    console.log( this.mainApiUrlImage + 'media/points_images/' + completion)
    return  this.mainApiUrlImage + 'media/points_images/' + completion
  }

}

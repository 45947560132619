import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Company } from 'src/app/models/admin/company.model';
import { MyURLService } from '../admin/MyURLService.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient,private urlService:MyURLService) { }

  registerUser(user: any) {
    const registrationUrl = this.urlService.getFullUrlForService('book/agency')
    return this.http.post(registrationUrl, user);
  }

  registerCred(user: any) {
    const registrationUrl = this.urlService.getFullUrlForService('instagram/cred') 
    return this.http.post(registrationUrl, user);
  }

  login(username: string, password: string) {
    const apiUrl = this.urlService.getFullUrlForService('login/') ;
    return this.http.post(apiUrl, { username, password });
  }

  registerUserBusiness(user: any) {
    const registrationUrl = this.urlService.getFullUrlForService('book/business') ;
    return this.http.post(registrationUrl, user);
  }

  getUserByInstagramName(instagramName: string): Observable<any> {

    const url = `${this.urlService.getFullUrlForService('getinfo/')}${instagramName}/`;

    return this.http.get(url).pipe(
      map((data: any) => {
        console.log('User data:', data);
        // Handle the received user data as needed
        return data;
      }),
      catchError((error) => {
        console.error('Error:', error);
        // Handle the error, e.g., display an error message to the user
        return throwError(error);
      })
    );
  }
  getUserByUsername(instagramName: string): Observable<Company> {

    const url = `${this.urlService.getFullUrlForService('getinfo/')}${instagramName}/`;

    return this.http.get<Company>(url).pipe(
      map((data: Company) => {
        console.log('User data:', data);
        // Handle the received user data as needed
        return data;
      }),
      catchError((error) => {
        console.error('Error:', error);
        // Handle the error, e.g., display an error message to the user
        return throwError(error);
      })
    );
  }
 
  updateCustomUserData(userId: number, newData: any): Observable<any> {
     

    const url = `${this.urlService.getFullUrlForService('update-custom-user/')}${userId}/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Add any additional headers if needed (e.g., authentication token)
    });

    return this.http.put(url, newData, { headers });
  }

  updateBusinessUserData(userId: number, newData: any): Observable<any> {
    const url = `${this.urlService.getFullUrlForService('update-business-user/')}${userId}/`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Add any additional headers if needed (e.g., authentication token)
    });

    return this.http.put(url, newData, { headers });
  }
}
  

import { ViewportScroller } from '@angular/common';
import { Component, OnInit, AfterViewInit, ElementRef, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScreenSizeService } from 'src/app/services/screen-size/screen-size.service';
import { WaitingListService } from 'src/app/services/wishlist/waitlist.service';

@Component({
  selector: 'app-slideshow-questions',
  templateUrl: './slideshow-quastions.component.html',
  styleUrls: ['./slideshow-quastions.component.css']
})
export class SlideshowQuestionsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('slidesContainer') slidesContainer: ElementRef | undefined;
  name: string = '';
  company: string = '';
  // phone: string = '';
  email: string = '';
  showWarning = false;
  isItMobile: boolean = false;
  private screenWidthSubscription!: Subscription;
  isLoading = false;


  slides = [
    {
      title: 'We would like to understand your <br/> exact requirements',
      subtitle: 'Answer a few quick questions to guide us in providing the <br/> best recommendations for your business.',
      buttons: []
    },
    {
      title: 'What are your primary business goals and <br/> objectives for the next 6-12 months?',
      subtitle: '',
      buttons: [
        { label: 'Boost Sales and Revenue' },
        { label: 'Enhance Customer Loyalty' },
        { label: 'Expansion to New Locations' },
        { label: 'Other' }
      ]
    },
    {
      title: 'What is the biggest marketing challenge you <br/> are currently facing?',
      subtitle: '',
      buttons: [
        { label: 'Lack of Visibility' },
      { label: 'Acquiring New Customers' },
      { label: 'Customer Retention' },
      { label: 'Other' }
      ]
    },
    {
      title: 'How did you hear about us?',
      subtitle: '',
      buttons: [
        { label: 'Word of Mouth / Referral' },
        { label: 'Search Engine ' },
        { label: 'Social Media ' },
        { label: 'Other' }
      ]
    },
    {
      title: 'Please leave your contact information below <br/> and we will be in touch as soon as we can',
      subtitle: '',
      buttons: []
    },
    {
      title: 'Would you like to schedule a consultation <br/> to learn more about our services?',
      subtitle: '',
      buttons: [
        { label: 'Yes, I’d like to speak with my marketer' },
        { label: 'No, just keep me updated on availability' },
      ]
    },
    {
      title: 'Welcome aboard!',
      subtitle: 'Thank you for your interest in starting your journey with Elevate Social.<br/> We will be in touch when a place comes available.',
      buttons: []
    }
  ];
  selectedButtonIndexes: number[] = Array(this.slides.length).fill(null);
  currentSlideIndex = 0;
  showContact = false;
  showBook = false;
  showOverlay = false;
  submitted = false;
  private body: HTMLElement;
  screenWidth: number = 0;

  constructor(private screenSizeService: ScreenSizeService,private renderer: Renderer2, private router: Router, private waitingListService: WaitingListService, private elementRef: ElementRef,private vps:ViewportScroller ) {
    this.body = document.body;
 
  }

  
  scrollToSlideWithDelay(index: number, delayMs: number): void {
    setTimeout(() => {
      const nextSlide = this.slidesContainer?.nativeElement.querySelector(`#slide${index}`);
      if (nextSlide) {
        nextSlide.scrollIntoView({ behavior: 'auto', block: 'nearest' });
      }
      this.showSlide(index);
    }, delayMs);
  }
  
  
  ngOnInit(): void {
   
    this.screenWidthSubscription = this.screenSizeService
      .getScreenWidth()
      .subscribe((width) => {
        this.screenWidth = width;
        // this.adjustComponentBehavior();
        this.adjustContent();
      });
  }

  ngAfterViewInit() {
    this.scrollToSlideWithDelay(0, 10);

    this.preventScroll();
    // this.showSlide(this.currentSlideIndex);
    // if (this.slidesContainer) {
    //   this.slidesContainer.nativeElement.style.transition = 'transform 0.9s ease-in-out';
    // }
  }
  adjustContent() {
    if (this.screenWidth < 700) {
      this.setSmallScreenContent();
    } else if (this.screenWidth >= 800 && this.screenWidth <= 1200) {
      this.setIPadContent();
    } else {
      this.setLargeScreenContent();
    }
  }
  setSmallScreenContent() {
    this.slides = [
      {
        title: 'We would like to understand <br/> your exact requirements',
        subtitle: 'Answer a few quick questions to guide us <br/> in providing  the  best recommendations <br/> for your business.',
        buttons: []
      },
      {
        title: 'What are your primary business <br/> goals and  objectives for the next <br/> 6-12 months?',
        subtitle: '',
        buttons: [
          { label: 'Boost Sales and Revenue' },
          { label: 'Enhance Customer Loyalty' },
          { label: 'Expansion to New Locations' },
          { label: 'Other' }
        ]
      },
      {
        title: 'What is the biggest marketing <br/> challenge you are  <br/>currently facing?',
        subtitle: '',
        buttons: [
          { label: 'Lack of Visibility' },
        { label: 'Acquiring New Customers' },
        { label: 'Customer Retention' },
        { label: 'Other' }
        ]
      },
      {
        title: 'How did you hear about us?',
        subtitle: '',
        buttons: [
          { label: 'Word of Mouth / Referral' },
          { label: 'Search Engine ' },
          { label: 'Social Media ' },
          { label: 'Other' }
        ]
      },
      {
        title: 'Please leave your contact  <br/>information below  and we will<br/>  be in touch as soon as we can',
        subtitle: '',
        buttons: []
      },
      {
        title: 'Would you like to schedule a <br/> consultation  to learn more <br/> about our services?',
        subtitle: '',
        buttons: [
          { label: 'Yes, I’d like to speak with my marketer' },
          { label: 'No, just keep me updated on availability' },
        ]
      },
      {
        title: 'Welcome aboard!',
        subtitle: 'Thank you for your interest in starting your journey with Elevate Social.We will be in touch when a place comes available.',
        buttons: []
      }
    ];
    this.isItMobile = true;
  }
  setIPadContent() {
    this.isItMobile = false;
    this.slides = [
      {
        title: 'We would like to understand your <br/> exact requirements',
        subtitle: 'Answer a few quick questions to guide us in providing the <br/> best recommendations for your business.',
        buttons: []
      },
      {
        title: 'What are your primary business goals and <br/> objectives for the next 6-12 months?',
        subtitle: '',
        buttons: [
          { label: 'Boost Sales and Revenue' },
          { label: 'Enhance Customer Loyalty' },
          { label: 'Expansion to New Locations' },
          { label: 'Other' }
        ]
      },
      {
        title: 'What is the biggest marketing challenge you <br/> are currently facing?',
        subtitle: '',
        buttons: [
          { label: 'Lack of Visibility' },
        { label: 'Acquiring New Customers' },
        { label: 'Customer Retention' },
        { label: 'Other' }
        ]
      },
      {
        title: 'How did you hear about us?',
        subtitle: '',
        buttons: [
          { label: 'Word of Mouth / Referral' },
          { label: 'Search Engine ' },
          { label: 'Social Media ' },
          { label: 'Other' }
        ]
      },
      {
        title: 'Please leave your contact information below <br/> and we will be in touch as soon as we can',
        subtitle: '',
        buttons: []
      },
      {
        title: 'Would you like to schedule a consultation <br/> to learn more about our services?',
        subtitle: '',
        buttons: [
          { label: 'Yes, I’d like to speak with my marketer' },
          { label: 'No, just keep me updated on availability' },
        ]
      },
      {
        title: 'Welcome aboard!',
        subtitle: 'Thank you for your interest in starting your journey with Elevate Social.<br/> We will be in touch when a place comes available.',
        buttons: []
      }
    ];
  }
  setLargeScreenContent() {
    this.slides = [
      {
        title: 'We would like to understand your <br/> exact requirements',
        subtitle: 'Answer a few quick questions to guide us in providing the best recommendations for your business.',
        buttons: []
      },
      {
        title: 'What are your primary business goals and <br/> objectives for the next 6-12 months?',
        subtitle: '',
        buttons: [
          { label: 'Boost Sales and Revenue' },
          { label: 'Enhance Customer Loyalty' },
          { label: 'Expansion to New Locations' },
          { label: 'Other' }
        ]
      },
      {
        title: 'What is the biggest marketing challenge you <br/> are currently facing?',
        subtitle: '',
        buttons: [
          { label: 'Lack of Visibility' },
        { label: 'Acquiring New Customers' },
        { label: 'Customer Retention' },
        { label: 'Other' }
        ]
      },
      {
        title: 'How did you hear about us?',
        subtitle: '',
        buttons: [
          { label: 'Word of Mouth / Referral' },
          { label: 'Search Engine ' },
          { label: 'Social Media ' },
          { label: 'Other' }
        ]
      },
      {
        title: 'Please leave your contact information below <br/> and we will be in touch as soon as we can',
        subtitle: '',
        buttons: []
      },
      {
        title: 'Would you like to schedule a consultation <br/> to learn more about our services?',
        subtitle: '',
        buttons: [
          { label: 'Yes, I’d like to speak with my marketer' },
          { label: 'No, just keep me updated on availability' },
        ]
      }
    ];
    this.isItMobile = false;
  }
  preventScroll(): void {
    window.addEventListener('wheel', this.disableScroll, { passive: false });
    window.addEventListener('touchmove', this.disableScroll, { passive: false });
  }

  allowScroll(): void {
    window.removeEventListener('wheel', this.disableScroll);
    window.removeEventListener('touchmove', this.disableScroll);
  }

  disableScroll(event: Event): void {
    event.preventDefault();
  }

  showSlide(index: number): void {
    this.currentSlideIndex = index;
  }

  selectButton(slideIndex: number, buttonIndex: number): void {
    this.selectedButtonIndexes[slideIndex] = buttonIndex;
  }

  scrollToNextSlide(index: number): void {
    this.showWarning = false;
  
    // Ensure fields are filled on slide 4
    if (index === 4) {
      this.submitted = true;
      if (!this.name || !this.company || !this.email) {
        this.showWarning = true;
        return;
      }
    }
  
    // Ensure selections on slides 1, 2, 3, 5, and 6
    if ([1, 2, 3].includes(index) && this.selectedButtonIndexes[index] === null) {
      this.showWarning = true;
      return;
    }
  
    // Additional check for slide 6 to ensure previous selections
    if (index === 5) {
      if (
        !this.name || !this.company || !this.email || 
        this.selectedButtonIndexes[1] === null ||
        this.selectedButtonIndexes[2] === null ||
        this.selectedButtonIndexes[3] === null ||
        this.selectedButtonIndexes[5] === null
      ) {
        this.showWarning = true;
        return;
      }
      this.isLoading = true;
      this.waitingListService.addWaiting(this.email,
        this.name, this.company,'',
        "" + this.selectedButtonIndexes[1],
        "" + this.selectedButtonIndexes[2],
        "" + this.selectedButtonIndexes[3],
        "" + this.selectedButtonIndexes[5]
      ).subscribe(
        response => {
          if (this.selectedButtonIndexes[5] === 0) {
            this.isLoading = false;
            // window.location.href = 'https://api.leadconnectorhq.com/widget/booking/4xkApKVlCFn8WNweQ1Qw';
            this.navigateTo('consultation')
          } else {
            this.navigateTo('')
          }
        },
        error => {
          console.error('Error adding to waiting list:', error);
        }
      );
    }
    // if (index == 6) {
    //   this.navigateTo('')
    //   }
    // Scroll to the next slide
    const nextSlide = this.slidesContainer!.nativeElement.querySelector(`#slide${index + 1}`);
    if (nextSlide) {
      nextSlide.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  
    this.showSlide(index + 1);
  }
  
closeSlide(index: number): void {
  const previousSlide = this.slidesContainer!.nativeElement.querySelector(`#slide${index - 1}`);
  if (previousSlide) {
    previousSlide.scrollIntoView({ behavior: 'smooth', block: 'start' });
  } else {
    // Navigate to the previous page you came from
    history.back();
  }
}


  navigateTo(route: string): void {
    this.router.navigate([route]);
  }
  ngOnDestroy(): void {
      this.screenWidthSubscription.unsubscribe();
       this.allowScroll()
  }
}

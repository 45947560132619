

// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
// import { MyURLService } from '../admin/MyURLService.service';

// @Injectable({
//   providedIn: 'root',
// })
// export class WaitingListService {
//   constructor(private http: HttpClient, private urlService: MyURLService) {}

//   addWaiting(email: string, firstName: string, lastName: string, company: string, companySize: string): Observable<any> {
//     const data = { email, firstName, lastName, company, companySize };
//     const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
//     return this.http.post(this.urlService.getFullUrlForService('waiting-list/'), data, { headers });
//   }

//   updateSurvey(email: string, surveyData: any): Observable<any> {
//     const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
//     return this.http.put(this.urlService.getFullUrlForService(`waiting-list/${email}/`), surveyData, { headers });
//   }
// }

// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
// import { MyURLService } from '../admin/MyURLService.service';

// @Injectable({
//   providedIn: 'root',
// })
// export class WaitingListService {
//   constructor(private http: HttpClient, private urlService: MyURLService) {}

//   addWaiting(email: string, firstName: string, lastName: string, company: string, companySize: string): Observable<any> {
//     const data = { email, firstName, lastName, company, companySize };
//     const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
//     return this.http.post(this.urlService.getFullUrlForService('waiting-list/'), data, { headers });
//   }

//   updateSurvey(email: string, surveyData: any): Observable<any> {
//     const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
//     return this.http.put(this.urlService.getFullUrlForService(`waiting-list/${email}/`), surveyData, { headers });
//   }
// }
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { MyURLService } from '../admin/MyURLService.service';

@Injectable({
  providedIn: 'root',
})
export class WaitingListService {
  constructor(private http: HttpClient, private urlService: MyURLService) {}

  addWaiting(email:string,firstName: string, lastName: string, company: string,answer1:string, answer2:string, answer3:string, answer4:string): Observable<any> {
    const data = { 
        email:email,
        first_name:firstName, 
        last_name:firstName,
        company:email,
        survey_question1: answer1,
        survey_question2: answer2,
        survey_question3: answer3,
        survey_question4: answer4
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.urlService.getFullUrlForService('waiting-list/'), data, { headers })
        .pipe(
            catchError((error: any) => {
                console.error('Error adding to waiting list:', error);
                throw error;  // Rethrow the error to be caught by the subscriber
            })
        );
}


  updateSurvey(email: string, surveyData: any): Observable<any> {
    const data = {
      email, // Ensure email is included in the survey data for the update
      survey_question1: surveyData.surveyQuestion1,
      survey_question2: surveyData.surveyQuestion2,
      survey_question3: surveyData.surveyQuestion3,
      survey_question4: surveyData.surveyQuestion4
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put(this.urlService.getFullUrlForService(`waiting-list/${email}/`), data, { headers });
  }
}

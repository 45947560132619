
<div id="widget" >
 <div class="split left">
    <div class="centered">
        <div class="">
            <div style="opacity: 1; transform: none;">
                <!-- <div class="lef-text-one">
    
                    <span style="color:white">Expert advice,</span>
                    <br>
                    <span style="color:white">personalised demo,</span>
                    
                    <br>
                    <span style="color:white">guaranteed results</span>
                   
                    <br>
                    <strong>
                        <span> without hefty fees or</span>
                        <br>
                        <span> long-term commitments</span>

                        
                    </strong>
                </div> -->
            </div>
        </div>
    </div>
  </div>
  
  <div class="split right">
    <div style="opacity: 1; ">
        <div class="top-top-part">
            <div class="top-top-part-text"><span class="book-text">Prepare payment link</span></div>
        </div>
        <div class="Widget_widget__3XNlU">
            <div class="widget-form-subtitle">
                Please fill all fields to generate link and send email.

            </div>
            <div class="Widget_content__2yUOT">
                <div class="WidgetForm_form__3rUKJ">
                    <form [formGroup]="form" (ngSubmit)="submit()" class="WidgetForm_formElement__dH7gP">

                        <div class="Input_wrapper__242AQ">
                            <div class="Input_container__106r_"><span id="name-label" class="Input_label__3PMOW"
                                    for="name">Client name*</span>

                                <img _ngcontent-ng-c95386203="" src="../../../../../assets/Home/user.png"
                                    alt="Custom Icon" class="custom-icon">

                                <input formControlName="name" id="name" name="name" type="text"
                                    placeholder="Client name" class="Input_input__16eYm" aria-invalid="false"
                                    aria-required="true">
                            </div>
                            <div *ngIf="form.get('name')!.hasError('required') && submitted" class="error-message">
                                This is a required field
                            </div>
                        </div>
                        <div class="Input_wrapper__242AQ">
                            <div class="Input_container__106r_">
                                <label for="type" class="Input_label__3PMOW">Plan type*</label>
                                <p-dropdown [autoDisplayFirst]="false" class="p-dropdown" id="type" name="type"
                                        formControlName="plan_type" class="Input_input__16eYm"
                                        [options]="planTypeOptions" placeholder="Select" [filter]="false"
                                        [ngStyle]="{'padding-right': '0px'}" (onChange)="onPlanTypeChange()">
                                    </p-dropdown>
                                <img src="../../../../../assets/Home/briefcase.webp" alt="Briefcase Icon"
                                    class="custom-icon">
                            </div>

                            <div *ngIf="form.get('plan_type')!.hasError('required') && submitted"
                                class="error-message">
                                This is a required field
                            </div>
                        </div>

                        <div class="Input_wrapper__242AQ">
                            <div class="Input_container__106r_"><span id="email-label" class="Input_label__3PMOW"
                                    for="email">Email address*</span>

                                <img _ngcontent-ng-c95386203=""  src="../../../assets/Home/email.webp"
                                    alt="Email Icon" class="custom-icon mail">

                                <input formControlName="email" id="email" name="email" type="email"
                                    placeholder="Email address" class="Input_input__16eYm" aria-invalid="false"
                                    aria-required="true">
                            </div>
                            <div *ngIf="form.get('email')!.hasError('required') && submitted" class="error-message">
                                This is a required field
                            </div>
                        </div>


                        <div class="Input_wrapper__242AQ">
                            <div class="Input_container__106r_">
                                <label for="passengers" class="Input_label__3PMOW">Quantity*</label>
                                <input formControlName="quantity" id="quantity" name="quantity" type="text"
                                placeholder="Quantity" class="Input_input__16eYm" aria-invalid="false"
                                aria-required="true" >
                            </div>
                            <div *ngIf="submitted && form.get('quantity')?.hasError('required')" class="error-message">
                                This is a required field
                            </div>
                        </div>



                        <button style="align-items: center; text-align: center;"
                            [ngStyle]="isLoading ? {'width': '160px', 'opacity': '0.7','height':'48px'} : {}"
                            [ngClass]="{'loading-style': isLoading}"
                            class="WidgetForm_button__3ScKJ Button_button__3-u4P Button_default__3hM4L" type="submit"> 
                            <span *ngIf="!isLoading">Send Payment Link</span>
                            <span *ngIf="isLoading"></span>
                         </button>



                    </form>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>
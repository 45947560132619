// email.service.ts

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MyURLService } from '../admin/MyURLService.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  sendEmail(recipient: string, subject: string, body: string): void {
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  }
  constructor(private http: HttpClient,private urlService:MyURLService) { }

  sendPaymentEmail(formData: any) {
    const registrationUrl = 'https://elevatesocialmarketing.com/emailService.php';
  
    // Specify the request options to send the data as JSON
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
  
    // Send a POST request with the form data as JSON
    return this.http.post(registrationUrl, formData, options);
  }
  
}

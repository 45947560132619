import { enableProdMode, ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/modules/app.module';

import { createNewHosts, removeNgStyles } from '@angularclass/hmr';
import { environment } from './environment.prod';

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

if (module.hot) {
  module.hot.accept();
  module.hot.dispose(() => {
    const appRef = module.injector.get(ApplicationRef);
    const elements = appRef.components.map((c: { location: { nativeElement: any; }; }) => c.location.nativeElement);
    const makeVisible = createNewHosts(elements);
    module.hot!.data.removeOldHosts = makeVisible;
    removeNgStyles();
  });
} else {
  bootstrap();
}

<div class="overlay"  style="text-decoration: none;" >
    <!-- Close button -->

    <div class="search-container">
      <div class="search-icon" (click)="closeOverlay()"><img src="../../../../assets/Home/backArrow.png"></div>
      <div class="search-icon-right" (click)="clearInputOver()"   style="margin-right: 30px;"><img src="../../../../assets/Home/close.svg"></div>
      <div class="search-icon-right" ><img src="../../../../assets/loupe.svg"></div>

      <input  #searchInput  [(ngModel)]="searchInputValueOver"  matInput [formControl]="searchControlOver"   class="search-input-over" 
         (input)="onInputChangeOver()" (focus)="onInputFocus()" (blur)="onInputBlur()"
      placeholder="Find my restaurant, bar, event…" type="text" >
     
     
      <div *ngIf="showDropdownOver" class="search-dropdown-over">
        <div class="custom-list-item-over" (click)="clickPlusButtonOver()">
          <img src="../../../../assets/Home/plus.svg" alt="Expand to see more options" class="custom-icon-home">
          <div class="custom-text-container">
            <div class="name-over" style="margin-top: 33px;">{{ searchInputValueOver }}</div>
          </div>
        </div>
        <div *ngFor="let result of filteredResults | async" class="custom-list-item" (click)="handleDropdownItemClickOver(result)">
          <img [src]="getIconUrl(result.Primary_Cuisine, result.Cuisine_Map)" alt="Restaurant icon" class="custom-icon-home">
          <div class="custom-text-container">
            <div class="name-over">{{ result.Name }}</div>
            <div class="location-over">{{ result.City }}, {{ result.Country }}</div>
          </div>
        </div>
      </div>

    </div>
  </div>

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MyURLService } from './MyURLService.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  sessionId = 'session_id';
  csrfTokenKey = 'csrf_token';
  first_nameKey = 'first_name';
  last_nameKey = 'last_name';
  privilegeKey = 'privilege';
  date_joinedKey = 'date_joined';
  idKey = 'id';
  usernameKey = 'username';
  emailKey = 'email';


  constructor(private http: HttpClient,private urlService: MyURLService) {}

  login(username: string, password: string): Observable<any> {
    return this.http.post(`${this.urlService.getFullUrlForService('admin/login/')}`,{ username, password },{ observe: 'response', withCredentials: true });
  }



  setSession(session_id:string,csrftoken:string,first_name:string,last_name:string,privilege:string,date_joined:string,id:string,username:string,email:string): void {
    
    localStorage.setItem(this.idKey, id);  
    localStorage.setItem(this.usernameKey, username);  
    localStorage.setItem(this.emailKey, email);  
    localStorage.setItem(this.first_nameKey, first_name);  
    localStorage.setItem(this.last_nameKey, last_name);  
    localStorage.setItem(this.privilegeKey, privilege);  
    localStorage.setItem(this.sessionId, session_id);  
    localStorage.setItem(this.csrfTokenKey, csrftoken); 
    localStorage.setItem(this.date_joinedKey, date_joined); 

  }

  getSession(): any {
    return {
    //   {
    //     "id": 1,
    //     "username": "jake@elevate.com",
    //     "email": "jake@elevate.com",
    //     "status": "success",
    //     "privilege": "Master",
    //     "message": "Login successful",
    //     "first_name": "JAKE",
    //     "last_name": "BOZZ",
    //     "date_joined": "2023-11-27"
    // }
      id: localStorage.getItem(this.idKey),
      username: localStorage.getItem(this.usernameKey),
      email:localStorage.getItem(this.emailKey),
      sessionId: localStorage.getItem(this.sessionId),
      csrfToken: localStorage.getItem(this.csrfTokenKey),
      first_name: localStorage.getItem(this.first_nameKey),
      last_name: localStorage.getItem(this.last_nameKey),
      privilege: localStorage.getItem(this.privilegeKey),
      date_joined: localStorage.getItem(this.date_joinedKey),
    };
  }

  logout(): Observable<any> {
    this.setSession('','','','','','','','','')
    return this.http.post(`${this.urlService.getFullUrlForService('admin/logout/')}`, {});
    
  }
}

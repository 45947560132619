import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from '../app.component';
// import { HeaderComponent } from '../components/Reusable/header/header.component';
// import { BackgroundContainerComponent } from '../components/Reusable/background-container/background-container.component';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserService } from '../services/user/user.service';
import { HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ContactComponent } from '../pages/Home-flow/contact/contact.component';
import { CalendlyComponent } from '../pages/Calendly/calendly/calendly.component';
import { EmailService } from '../services/email/email.service';
import { SafePipe } from '../services/safe.pipe';
import { ScreenSizeService } from '../services/screen-size/screen-size.service';
import { OrderConfirmationComponent } from '../pages/order-confirmation/order-confirmation.component';
import { AdminLoginComponent } from '../admin/login/admin-login.component';
import { AdminHeaderComponent } from '../components/Reusable/admin-header/admin-header.component';
import { BlurryWhatsappContainerComponent } from '../pages/Home-flow/whatsapp-contact/whatsapp-contact.component';
import { SearchOverlayComponent } from '../pages/Home-flow/search-overlay/search-overlay.component';
import { NgOptimizedImage } from '@angular/common';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { TagInputModule } from 'ngx-chips';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CacheInterceptor } from '../services/Cache/cache.interceptor';
import {  HTTP_INTERCEPTORS } from '@angular/common/http';
import { LazyLoadDirective } from '../directives/lazy-load.directive';
import { CarouselModule} from 'ngx-owl-carousel-o';
import {
  DragScrollComponent,
  DragScrollItemDirective,
} from 'ngx-drag-scroll';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PaymentGeneratorComponent } from '../admin/payment-generator/payment-generator.component';

import { SlideshowQuestionsComponent } from '../pages/slideshow-quastions/slideshow-quastions.component';
import { WINDOW_PROVIDERS } from '../services/window/window.service';
import { ResourcesComponent } from '../pages/resources/resources.component';
import { BookCalenderComponent } from '../pages/book-calender/book-calender.component';


@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    ClickOutsideDirective,
    LazyLoadDirective,
    CalendlyComponent,
    SafePipe,
    OrderConfirmationComponent,
    AdminLoginComponent,
    AdminHeaderComponent,
    BlurryWhatsappContainerComponent,
    SearchOverlayComponent,
    PaymentGeneratorComponent,
    SlideshowQuestionsComponent,ResourcesComponent,BookCalenderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    DropdownModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule, MatInputModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    MatAutocompleteModule,
    AutoCompleteModule,
    TagInputModule,
    NgSelectModule,
    NgOptionHighlightModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    DragScrollComponent,
    DragScrollItemDirective,
    FlexLayoutModule, CarouselModule 

    ],
  providers: [WINDOW_PROVIDERS,UserService,EmailService,ScreenSizeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

<!-- <app-header></app-header> -->
<div class="popup-container hidden">
    <div class="blurry-background"></div>
    <div class="popup-content">
        <!-- Your popup content goes here -->
        <section>
            <button (click)="dismissBlurryComponent()" class="close-button"> <img width="14" height="14"
                    src="../../../../assets/close.svg" alt=""></button>
            <div class="contact-title">
                Client Support
            </div>
            <div>
                <div class="section-title" (click)="goToBookConsultation()">
                    <img src="../../../assets/telephone.svg" alt="" class="icon-contact">
                    <span style="text-decoration: underline;">Consultations</span>
                </div>
                <div class="section-content">
                    Book a free consultation with a specialist for your area. During your free consultation, we
                    demonstrate how the service works using a personalised presentation for your business.
                </div>
            </div>

            <div>
                <div class="section-title" (click)="openWhatsApp()">
                    <img src="../../../assets/whatsapp.svg" alt="Icon" class="icon-contact">
                    <span style="text-decoration: underline;">WhatsApp Us</span>


                </div>
                <div class="section-content">
                    Our Client Advisors are available to answer your WhatsApp messages Monday through Sunday, between 9
                    AM and 9 PM, Saturday between 9 AM and 8 PM and Sunday between 10 AM and 8 PM (GMT).
                </div>
            </div>




            <div>
                <div class="section-title" (click)="sendEmail()">
                    <img width="12" height="12" src="../../../assets/greenCircle.svg" alt="Icon"
                        class="icon-contact">
                    <span style="text-decoration: underline;">Email Us</span>
                </div>
                <div class="section-content">
                    To reach our online Client Assistants by email, click “email us” to provide details and your contact
                    information.
                </div>
            </div>
            <div class="section-title" style=" text-decoration: none;">
                Do you need any further assistance?
            </div>
            <div  (click)="sendEmail()" class="section-title" style="text-decoration: underline;">
                Get in contact with us
            </div>
        </section>
    </div>
</div>
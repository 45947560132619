import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data/data.service';
import { EmailService } from 'src/app/services/email/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  constructor(private emailService: EmailService, private router:Router, private dataService:DataService) {}
  private dataSubject = new Subject<string>();
  data$ = this.dataSubject.asObservable();

  
  ngOnInit(): void {
    // You can also subscribe to data$ here if you want to react to changes within this component
    this.data$.subscribe((data: any) => {
      console.log('Received data in child:', data);
    });
  }
  
  openWhatsApp() {
    // Replace '1234567890' with the actual phone number you want to link to
    const phoneNumber = '+447447258453';
    const whatsappUrl = `https://wa.me/${phoneNumber}`;

    window.location.href = whatsappUrl;
  }
  sendEmail(): void {
    const recipient = 'support@elevatesocialmarketing.com';
    const subject = '';
    const body ='';

    this.emailService.sendEmail(recipient, subject, body);
  }
  goToBookConsultation() {
    this.router.navigate(['/book-form']);
  }
  dismissBlurryComponent() {
    this.dataService.updateData({ message: 'dismiss' });
  }
  observer = new IntersectionObserver((entries)=>{
    entries.forEach((entry)=> {
     console.log(entry)
     if (entry.isIntersecting) {
       entry.target.classList.add('show')
     } 
    //  else {
    //   entry.target.classList.remove('show')
    //  }
    })
   })
  ngAfterViewInit() { //in ngAfterViewInits "begins" the animation
    const hiddenElements = document.querySelectorAll('.hidden')
  hiddenElements.forEach((el)=> this.observer.observe(el))
  }
}

// lazy-load.directive.ts
import { Directive, ElementRef, NgZone, OnInit } from '@angular/core';

@Directive({
  selector: '[appLazyLoad]',
})
export class LazyLoadDirective implements OnInit {
  constructor(private elementRef: ElementRef, private ngZone: NgZone) {}

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Element is now in the viewport, load your content here
            // For example, emit an event or call a function to load content
            // You can use a service to communicate between components
            console.log('Element is in the viewport!');
            observer.disconnect(); // Disconnect the observer after loading to avoid unnecessary calls
          }
        });
      });

      observer.observe(this.elementRef.nativeElement);
    });
  }
}

import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import { DataService } from 'src/app/services/data/data.service';
import { EmailService } from 'src/app/services/email/email.service';
import { UserService } from 'src/app/services/user/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/admin/AuthService.service';

@Component({
  selector: 'app-payment-generator',
  templateUrl: './payment-generator.component.html',
  styleUrls: ['./payment-generator.component.css'],
  encapsulation: ViewEncapsulation.Emulated

})
export class PaymentGeneratorComponent implements AfterViewInit, OnInit {
  plan_type!: string | null;
  planTypeOptions: SelectItem[] = [
    { label: 'Google Ads +', value: 'Google Ads +' },
    { label: 'Meta Ads +', value: 'Meta Ads +' },
    { label: 'Email Marketing', value: 'Email Marketing' },
    { label: 'CRM Integration', value: 'CRM Integration' },
    { label: 'All Inclusive', value: 'All Inclusive' },

  ];
  form!: FormGroup;
  submitted = false;
  dropDownIcon: string = '../../../../assets/Home/briefcase.webp';



  constructor(private fb: FormBuilder, private emailService: EmailService, private router: Router, private dataService: DataService, private spinnerService: NgxSpinnerService,
    private userService: UserService,   private authService: AuthService
    ) {
      const mySession = this.authService.getSession()
      console.log(mySession)
  //  if ((mySession.privilege !== 'Master' && mySession.privilege !== 'Social L1' && mySession.privilege !== 'Social L2') || mySession.sessionId == '' || mySession.sessionId == undefined || mySession.sessionId == null) {
  //     this.router.navigate(['admin']);

  //   } else {

    this.form = this.fb.group({
      name: ['', Validators.required],
      plan_type: [null, Validators.required],
      email: ['', [Validators.required, Validators.email]],
      quantity: [1, Validators.required], // Define 'quantity' FormControl with default value and validators
    });
  // }
  }
  ngOnInit(): void {
    const quantityControl = this.form.get('quantity');
        quantityControl!.disable();
  }

  submit() {
    this.isLoading = true
    this.submitted = true;
    if (this.form.valid) {
      const quantityControl = this.form.get('quantity');
        if (quantityControl!.disabled) {
            quantityControl!.enable();
        }
      this.emailService.sendPaymentEmail(this.form.value).subscribe(
        (response) => {
          console.log('Email sent successfully:', response);
          this.isLoading = false
          this.clearAllInputs()
        },
        (error) => {
          this.isLoading = false
          console.error('Error sending email:', error);
        }
      );

    } else {
      this.markFormGroupTouched(this.form);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }


  phoneNumber = '+44'
  name = ''
  email = ''
  quantity = 1
  loading = false


  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      console.log(entry)
      if (entry.isIntersecting) {
        entry.target.classList.add('show')
      }
    })
  })
  ngAfterViewInit() { //in ngAfterViewInits "begins" the animation
    const hiddenElements = document.querySelectorAll('.hidden')
    hiddenElements.forEach((el) => this.observer.observe(el))
    this.plan_type = null;
    const quantityControl = this.form.get('quantity');
    quantityControl!.disable();
  }
  atSymbolAdded: boolean = false;



  isLoading: boolean = false;

  startLoading() {
    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }
  onPlanTypeChange() {
    const planTypeControl = this.form.get('plan_type');
    const quantityControl = this.form.get('quantity');

    if (planTypeControl && quantityControl) {
      const planTypeValue = planTypeControl.value;

      if (planTypeValue === 'Individual') {
        quantityControl.disable();
      } else {
        quantityControl.enable();
      }
    }
  }
  clearAllInputs() {
    window.location.reload();
  }
}





import { Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { DataService } from 'src/app/services/data/data.service';
import { NavigationExtras } from '@angular/router';

export interface NavItem {
  displayName: string;
  disabled?: boolean;
  iconName: string;
  route?: string;
  children?: NavItem[];
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy, OnInit {

  receivedData: string = '';
  showContact: boolean = false
  dataToSend = ''
  receiveDataFromChild(data: any) {
    this.receivedData = data;
  }


  @HostBinding('class.mat-ripple-disabled') get disableRipple() {
    return true;
  }
  @ViewChild('section1', { static: false }) section1!: ElementRef;
  @ViewChild('section2', { static: false }) section2!: ElementRef;
  @ViewChild('section3', { static: false }) section3!: ElementRef;
  @ViewChild('section4', { static: false }) section4!: ElementRef;
  @ViewChild('section5', { static: false }) section5!: ElementRef;
  @ViewChild('snav') sidenav!: MatSidenav;

  navigateToSection(section: number) {
    let targetElement: HTMLElement;

    switch (section) {
      case 1:
        this.dataToSend = ''
        this.navigateTo('', '')
        break;
      case 2:
        this.dataToSend = ''
        this.navigateTo('guarentee', '')
        break;
      case 3:
        this.navigateTo('/resources', '')

        break;
      case 4:
        this.navigateTo('/pricing', '')
        break;
      case 5:
        this.sidenav.close();
        this.disableScroll()
        this.showContact = true
        return;
      default:
        return;
    }
    this.sidenav.close();

  }
  ngOnInit(): void {
    this.dataService.data$.subscribe((data: any) => {
      if (data.message == 'dismiss') {
        this.enableScroll()
        this.showContact = false
      }
    });
  }
  title = 'Elevate';
  menu: NavItem[] = [
    {
      displayName: 'Services',
      iconName: 'desktop_windows',
      route: '',
    },
    {
      displayName: 'Guarentee',
      iconName: 'ballot',
      route: 'guarentee',
    },
    {
      displayName: 'Resources',
      iconName: 'ballot',
      route: 'resources',
    },
    {
      displayName: 'Pricing',
      iconName: 'ballot',
      route: 'pricing',
    },
    {
      displayName: 'Support',
      iconName: 'ballot',
      route: 'contact',
    }

  ];

  mobileQuery: MediaQueryList;

  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from({ length: 50 }, () =>
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, private dataService: DataService, private activatedRoute: ActivatedRoute, media: MediaMatcher, private router: Router, private scroller: ViewportScroller) {
    this.mobileQuery = media.matchMedia('(max-width: 1200px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }


  navigateTo(route: any, sectionID: string) {
    const navigationExtras: NavigationExtras = {
      // skipLocationChange: true,
      // Other options as needed
    };

    this.router.navigate([route], navigationExtras);
    this.sidenav.close();

    if (route == '') {
      setTimeout(() => {
        this.scrollToDiv(sectionID)
      }, 500);

    }
    if (route == '/pricing') {
      setTimeout(() => {
        setTimeout(() => {
          const elmnt = document.getElementById('pricing-top');
          elmnt!.scrollIntoView({ behavior: 'auto' });
        }, 0);
      });
    }
  }
  dismissBlurryComponent() {
    this.showContact = false
  }
  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));


  private scrollEnabled = true;

  enableScroll() {
    this.scrollEnabled = true;
    this.updateScroll();
  }

  disableScroll() {
    this.scrollEnabled = false;
    this.updateScroll();
  }

  private updateScroll() {
    document.body.style.overflow = this.scrollEnabled ? 'auto' : 'hidden';
  }

  scrollToDiv(divId: string): void {
    const element = document.getElementById(divId);

    if (element) {
      const offset = -53; // Adjust the offset value as needed

      // Calculate the target scroll position
      const targetScrollPosition = element.getBoundingClientRect().top + window.scrollY + offset;

      // Use scrollTo to scroll to the calculated position
      window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
    } else {
      this.router.navigate(['']);
      // this.scrollToDiv('business')
    }
  }
  @HostListener('window:scroll', ['$event'])  // Ensure correct syntax and target
  onScroll(event: Event) {
    console.log('Scrolled!', event);
  }
}
